<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

import {mapState} from "vuex";


export default {
  page: {
    title: "Invoice",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    InvoiceRender: () => import('@/components/lazy/invoices/InvoiceRenderV1'),
  },
  validations: {

  },
  methods: {

  },
  created() {

  },
  data() {
    return {
      invoice_id: this.$route.params.invoice_id
    }
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18">
        Invoice
        <router-link to="/invoices" class="small ml-2">
          <i class="fas fa-backspace"></i> Go back
        </router-link>
      </h4>
      <div class="page-title-right bv-d-xs-down-none">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item">
            <router-link to="/">
              CFTools Cloud
            </router-link>
          </li>
          <li class="breadcrumb-item">
          <span aria-current="location">
            Account
          </span>
          </li>
          <li class="breadcrumb-item">
          <span aria-current="location">
            Invoices
          </span>
          </li>
          <li class="breadcrumb-item">
          <span aria-current="location">
            Details
          </span>
          </li>
        </ol>
      </div>
    </div>
    <InvoiceRender :invoice_id="invoice_id"/>
  </Layout>
</template>